import { useContext, useState } from "react";
import { ToasterContext } from "../contexts/ToasterContext";
import { BASE_TOAST_CONFIG, BaseToast } from "../components/toast/BaseToast";

export const useCopyToClipboard = (
  copiedMessage: string | undefined,
  isCopiedTimeout = 1500,
) => {
  const toast = useContext(ToasterContext);

  const [isCopiedToClipboard, setIsCopiedToClipboard] = useState(false);

  const copiedWithReset = () => {
    setIsCopiedToClipboard(true);

    setTimeout(() => {
      setIsCopiedToClipboard(false);
    }, isCopiedTimeout);
  };

  const copyToClipboard = (valueCopiedToClipboard: string) => {
    if (isCopiedToClipboard == true) {
      return;
    }

    toast(
      <BaseToast
        type={"success"}
        message={copiedMessage || "Successfully copied to clipboard."}
      />,
      BASE_TOAST_CONFIG,
    );

    navigator.clipboard.writeText(valueCopiedToClipboard);
    copiedWithReset();
  };

  return {
    isCopiedToClipboard,
    copyToClipboard,
  };
};
