import * as anchor from "@coral-xyz/anchor";
import { Program } from "@coral-xyz/anchor";
import {
  PublicKey,
  AddressLookupTableProgram,
  TransactionError,
} from "@solana/web3.js";
import { TOKEN_PROGRAM_ID } from "@coral-xyz/anchor/dist/cjs/utils/token";
import { sha256 } from "js-sha256";
import * as base58 from "bs58";

import House from "./house";
import { GameTokenStatus, GameType } from "./enums";
import { RANDOM_PROGRAM_PUBKEY } from "./constants";
import PlayerAccount from "./playerAccount";
import { toGameStatus, toGameTokenStatus, toGameType } from "./utils";
import { sleep } from "../utils/time/sleep";

export interface IGameToken {
  pubkey: string;
  status: GameTokenStatus;
}

export default class Game {
  private _program: Program;
  private _house: House;
  private _pubkey: PublicKey;
  private _state: any;
  private _stateLoaded: boolean;
  private _eventParser: anchor.EventParser;
  private _commitmentLevel: anchor.web3.Commitment;

  private _gameType: GameType;
  private _gameTypeName: String;

  constructor(
    casinoProgram: anchor.Program,
    house: House,
    gameSpecPubkey: PublicKey,
    gameType: GameType,
    commitmentLevel: anchor.web3.Commitment = "processed",
  ) {
    this._stateLoaded = false;
    this._program = casinoProgram;
    this._eventParser = new anchor.EventParser(
      this.program.programId,
      new anchor.BorshCoder(this.program.idl),
    );
    this._house = house;
    this._pubkey = gameSpecPubkey;
    this._commitmentLevel = commitmentLevel;
    this._gameType = gameType;
    this._gameTypeName = GameType[this._gameType];
  }

  static async load(
    casinoProgram: anchor.Program,
    house: House,
    gameSpecPubkey: PublicKey,
    gameType: GameType,
  ) {
    const gameSpec = new Game(casinoProgram, house, gameSpecPubkey, gameType);
    await gameSpec.loadState();
    return gameSpec;
  }

  static loadFromBuffer(
    casinoProgram: anchor.Program,
    house: House,
    gameSpecPubkey: PublicKey,
    accountBuffer: Buffer,
  ) {
    const state = casinoProgram.coder.accounts.decode(
      "GameSpec",
      accountBuffer,
    );
    const gameSpec = new Game(
      casinoProgram,
      house,
      gameSpecPubkey,
      toGameType(state.gameType),
    );
    gameSpec._state = state;
    return gameSpec;
  }

  async loadState(commitmentLevel: anchor.web3.Commitment = "processed") {
    const state = await this.program.account.gameSpec.fetchNullable(
      this.publicKey,
      commitmentLevel,
    );
    this._state = state;
    this._stateLoaded = true;
    return;
  }

  static getInstructionDiscriminatorFromSnakeCase(
    ixnNameSnakeCase: string,
  ): number[] {
    const preimage = `${"global"}:${ixnNameSnakeCase}`;
    const discriminatorBytes = Buffer.from(sha256.digest(preimage)).slice(0, 8);
    var discriminatorU8s: number[] = [];
    discriminatorBytes.forEach((b) => {
      discriminatorU8s.push(Number(b));
    });
    return discriminatorU8s;
  }

  async checkPlayerAccountInitialized(): Promise<boolean> {
    if (!this._stateLoaded) {
      await this.loadState();
    }
    if (this._state) {
      return true;
    } else {
      return false;
    }
  }

  async checkPlayerAccountSupportedToken(
    tokenMintPubkey: PublicKey,
  ): Promise<boolean> {
    if (!this.checkPlayerAccountInitialized()) {
      return false;
    }
    {
      if (this._state) {
        return true;
      } else {
        return this.supportedTokenMints.includes(tokenMintPubkey);
      }
    }
  }

  static deriveGameSpecPubkey(
    programId: PublicKey,
    housePubkey: PublicKey,
    gameType: GameType,
  ) {
    const [gameSpecPubkey, _] = PublicKey.findProgramAddressSync(
      [
        anchor.utils.bytes.utf8.encode("game_spec"),
        housePubkey.toBuffer(),
        new anchor.BN(gameType).toArrayLike(Buffer, "le", 1),
      ],
      programId,
    );
    return gameSpecPubkey;
  }

  static deriveGlobalSignerPubkey(programId: PublicKey) {
    const [globalSignerPubkey, _] = PublicKey.findProgramAddressSync(
      [anchor.utils.bytes.utf8.encode("global_signer")],
      programId,
    );
    return globalSignerPubkey;
  }

  static deriveRandomDispatcherPubkey(randomProgramId: PublicKey) {
    const [randomnessDispatcherSignerPubkey, _] =
      PublicKey.findProgramAddressSync(
        [anchor.utils.bytes.utf8.encode("dispatcher")],
        randomProgramId,
      );
    return randomnessDispatcherSignerPubkey;
  }

  deriveSoloGameInstancePubkey(userPubkey: PublicKey) {
    const [gameInstancePubkey, _] = PublicKey.findProgramAddressSync(
      [
        anchor.utils.bytes.utf8.encode("game_instance"),
        this.publicKey.toBuffer(),
        userPubkey.toBuffer(),
      ],
      this.program.programId,
    );
    return gameInstancePubkey;
  }

  get house() {
    return this._house;
  }

  get program() {
    return this._program;
  }

  get publicKey() {
    return this._pubkey;
  }

  get state() {
    return this._state;
  }

  get eventParser() {
    return this._eventParser;
  }

  get supportedTokens() {
    return this._state ? this._state.tokens : null;
  }

  get supportedTokenMints(): PublicKey[] {
    return this._state
      ? this._state.tokens.map((tkn) => {
          return tkn.pubkey;
        })
      : [];
  }

  get gameType() {
    return this._state ? Object.keys(this._state.gameType)[0] : null;
  }

  get gameTypeName() {
    return this._gameTypeName;
  }

  get gameTypeEnum() {
    return this._state.gameType;
  }

  get gameTypeNumber() {
    return this._gameType;
  }

  get status() {
    return this._state != null ? toGameStatus(this._state.status) : null;
  }

  get statusString() {
    return this._state != null ? Object.keys(this._state.status)[0] : null;
  }

  get authority() {
    return this._state != null ? this._state.authority : null;
  }

  get lookupTable() {
    return this._state != null ? this._state.lookupTable : null;
  }

  get isMultiplayer() {
    return this._state ? this._state.isMultiplayer : null;
  }

  get maxBetsPerIxn() {
    return this._state ? this._state.maxBetsPerIxn : null;
  }

  get hasRounds() {
    return this._state ? this._state.hasRounds : null;
  }

  get maxUsersToSettlePerIxn() {
    return this._state ? this._state.maxUsersToSettlePerIxn : null;
  }

  get maxBetsToSettlePerIxn() {
    return this._state ? this._state.maxBetsToSettlePerIxn : null;
  }

  get supportsMultiToken() {
    return this._state ? this._state.supportsMultiToken : null;
  }

  get maxTokensPerInstance() {
    return this._state ? this._state.maxUsersToSettlePerIxn : null;
  }

  get maxUsersPerInstance() {
    return this._state ? this._state.maxUsersPerInstance : null;
  }

  get maxBetsPerInstance() {
    return this._state ? this._state.maxUsersPerInstance : null;
  }

  get scheduledBy() {
    return this._state ? Object.keys(this._state.scheduledBy)[0] : null;
  }

  get instanceInterval() {
    return this._state ? Number(this._state.instanceInterval) : null;
  }

  get instanceNonce() {
    return this._state ? Number(this._state.instanceNonce) : null;
  }

  get activeInstances() {
    return this._state ? Number(this._state.activeInstances) : null;
  }

  get activeBets() {
    return this._state ? Number(this._state.activeBets) : null;
  }

  get pastInstances() {
    return this._state ? Number(this._state.pastInstances) : null;
  }

  get pastBets() {
    return this._state ? Number(this._state.pastBets) : null;
  }

  get lastUsedInstanceIdx() {
    return this._state ? Number(this._state.lastUsedInstanceIdx) : null;
  }

  get lastGamePubkey() {
    return this._state ? this._state.lastGame : null;
  }

  get currentGamePubkey() {
    return this._state ? this._state.currentGame : null;
  }

  get nextGamePubkey() {
    return this._state ? this._state.nextGame : null;
  }

  get gameSpecificDataConfigured() {
    return this._state ? this._state.gameSpecificDataConfigured : null;
  }

  get gameSpecificConfig() {
    return this._state ? this._state.gameSpecificConfig : null;
  }

  get numActiveTokens() {
    return this._state ? this._state.numActiveTokens : null;
  }

  get authorityPubkey() {
    return this._state ? this._state.authority : null;
  }

  get lookupTablePubkey() {
    return this._state ? this._state.lookupTable : null;
  }

  get cashierProgramPubkey() {
    return this._state ? this._state.cashierProgram : null;
  }

  get cashierSignerPubkey() {
    return this._state ? this._state.cashierSigner : null;
  }

  get randomnessProgramPubkey() {
    return this._state ? this._state.randomnessProgram : null;
  }

  get randomnessDispatcherSignerPubkey() {
    return this._state ? this._state.randomnessDispatcherSigner : null;
  }

  get randomnessCallbackDiscriminator() {
    return this._state ? this._state.randomnessCallbackDiscriminator : null;
  }

  get gameConfig() {
    return this._state ? this._state.config : null;
  }

  get gameConfigInner() {
    return this._state ? Object.values(this._state.config)[0] : null;
  }

  get minWagerInTokenMinUnits() {
    return this._state ? Number(this._state.minWagerInTokenMinUnits) : null;
  }

  get listTokens(): any[] {
    return this.state ? this.state.tokens : [];
  }

  get tokens(): IGameToken[] {
    return this.state
      ? this.state.tokens.map((token) => {
          return {
            pubkey: token.pubkey.toString(),
            status: toGameTokenStatus(token.status),
          };
        })
      : [];
  }

  getMaxBet(tokenMintPubkey: PublicKey, multiplier: number) {
    const houseTokenConfig =
      this.house.getTokenConfigAndStatistics(tokenMintPubkey);
    return Number(houseTokenConfig.availableTradingBalance) / (multiplier - 1);
  }

  deriveInstanceLookupTable(
    gameInstancePubkey: PublicKey,
    recentBlockSlot: number,
  ): PublicKey {
    const [instanceLookupTableIxn, instanceLookupTablePubkey] =
      AddressLookupTableProgram.createLookupTable({
        authority: gameInstancePubkey,
        payer: this.deriveGlobalSignerPubkey(),
        recentSlot: recentBlockSlot,
      });
    return instanceLookupTablePubkey;
  }

  deriveRandomnessRequestPubkey(
    callerNonce: anchor.BN,
    callerUniquePubkey: PublicKey,
  ) {
    const [randomnessRequestPubkey, randomnessRequestBump] =
      PublicKey.findProgramAddressSync(
        [
          anchor.utils.bytes.utf8.encode("request"),
          this.deriveGlobalSignerPubkey().toBuffer(),
          callerUniquePubkey.toBuffer(),
          callerNonce.toArrayLike(Buffer, "le", 8),
        ],
        RANDOM_PROGRAM_PUBKEY,
      );
    return randomnessRequestPubkey;
  }

  deriveGlobalSignerPubkey() {
    const [globalSignerPubkey, _] = PublicKey.findProgramAddressSync(
      [anchor.utils.bytes.utf8.encode("global_signer")],
      this.program.programId,
    );
    return globalSignerPubkey;
  }

  deriveNextSoloGameInstance(playerPubkey: PublicKey) {
    const [gameInstancePubkey, _] = PublicKey.findProgramAddressSync(
      [
        anchor.utils.bytes.utf8.encode("game_instance"),
        this.publicKey.toBuffer(),
        playerPubkey.toBuffer(),
      ],
      this.program.programId,
    );
    return gameInstancePubkey;
  }

  deriveDispatcherPubkey(programId: PublicKey): PublicKey {
    const [dispatcherPubkey, _] = PublicKey.findProgramAddressSync(
      [anchor.utils.bytes.utf8.encode("dispatcher")],
      programId,
    );
    return dispatcherPubkey;
  }

  subscribeToGameEvents(
    pubkeyFilter: PublicKey,
    onGameInstanceOrRoundCreatedEvent: Function,
    onBetPlaceEvent: Function,
    onBetResultEvent: Function,
    onGameInstanceResultEvent: Function,
    onGameInstanceClosedEvent: Function,
    onError?: Function,
  ) {
    const handleLogs = (
      logs: {
        err: TransactionError | null;
        logs: string[];
        signature: string;
      },
      context: {
        slot: number;
      },
    ) => {
      if (logs.err != null) {
        console.error("Error in Game WS Listener", logs.err);
        onError?.(logs.err);
        return;
      }

      const events = this.eventParser.parseLogs(logs.logs);
      const signature = logs.signature;

      for (let event of events) {
        if (event.name == "GameInstanceCreatedEvent") {
          if (onGameInstanceOrRoundCreatedEvent) {
            onGameInstanceOrRoundCreatedEvent({ ...event.data, signature });
          }
        } else if (event.name == "BetPlaceEvent") {
          if (onBetPlaceEvent) {
            onBetPlaceEvent({ ...event.data, signature });
          }
        } else if (event.name == "BetResultEvent") {
          if (onBetResultEvent) {
            onBetResultEvent({ ...event.data, signature });
          }
        } else if (event.name == "GameInstanceResultEvent") {
          if (onGameInstanceResultEvent) {
            onGameInstanceResultEvent({ ...event.data, signature });
          }
        } else if (event.name == "GameInstanceClosedEvent") {
          if (onGameInstanceClosedEvent) {
            onGameInstanceClosedEvent({ ...event.data, signature });
          }
        }
      }
    };

    return this.program.provider.connection.onLogs(
      pubkeyFilter,
      handleLogs,
      this._commitmentLevel,
    );
  }

  async subscribeToGameEventsPolling(
    gameInstancePubkey: PublicKey,
    onGameInstanceOrRoundCreatedEvent: Function,
    onBetPlaceEvent: Function,
    onBetResultEvent: Function,
    onGameInstanceResultEvent: Function,
    onGameInstanceClosedEvent: Function,
    onError: Function,
    lastSignature: string,
    timeoutS: number = 20,
  ) {
    const handleLogs = (
      txMeta: anchor.web3.ParsedTransactionWithMeta | null,
      signature: string,
    ) => {
      if (
        txMeta == null ||
        txMeta.meta == null ||
        txMeta.meta.logMessages == null
      ) {
        return;
      }

      const events = this.eventParser.parseLogs(txMeta.meta.logMessages);

      for (let event of events) {
        if (event.name == "GameInstanceCreatedEvent") {
          if (onGameInstanceOrRoundCreatedEvent) {
            onGameInstanceOrRoundCreatedEvent({ ...event.data, signature });
          }
        } else if (event.name == "BetPlaceEvent") {
          if (onBetPlaceEvent) {
            onBetPlaceEvent({ ...event.data, signature });
          }
        } else if (event.name == "BetResultEvent") {
          if (onBetResultEvent) {
            onBetResultEvent({ ...event.data, signature });
          }
        } else if (event.name == "GameInstanceResultEvent") {
          if (onGameInstanceResultEvent) {
            onGameInstanceResultEvent({ ...event.data, signature });
          }
        } else if (event.name == "GameInstanceClosedEvent") {
          if (onGameInstanceClosedEvent) {
            onGameInstanceClosedEvent({ ...event.data, signature });
          }
        }
      }
    };

    // VARS USED IN WHILE LOOP
    let isFinished = false; // HARD STOP
    let cycles = 0; // CHECK ON MAX CYCLES
    let stageOfCycle = 0; // WHERE IN CYCLE ARE WE - 0 = Looking for game instance, 1 = get logs for instance created, 2 = get logs for bet results

    while (isFinished == false) {
      cycles += 1;

      if (cycles > timeoutS) {
        onError("Too Many Tries To Get Game Result");

        return;
      }

      if (stageOfCycle == 0) {
        // GET LOGS FOR INITIAL TX SIG
        const logs =
          await this.program.provider.connection.getParsedTransaction(
            lastSignature,
            { commitment: "confirmed", maxSupportedTransactionVersion: 0 },
          );

        if (logs == null) {
          await sleep(1000);

          continue;
        } else {
          const signatures =
            await this.program.provider.connection.getSignaturesForAddress(
              gameInstancePubkey,
              {
                until: lastSignature,
              },
              "confirmed",
            );

          if (signatures == null || signatures.length == 0) {
            handleLogs(logs, lastSignature);
            stageOfCycle = 1;

            await sleep(1000);

            continue;
          } else {
            const parsedTxsWithMeta =
              await this.program.provider.connection.getParsedTransactions(
                signatures.map((signature) => {
                  return signature.signature;
                }),
                {
                  commitment: "confirmed",
                  maxSupportedTransactionVersion: 0,
                },
              );

            parsedTxsWithMeta.forEach((parsedTx, index) => {
              const signature = signatures[index].signature;

              handleLogs(parsedTx, signature);
            });

            isFinished = true;
          }
        }
      }

      if (stageOfCycle == 1) {
        // GOT THE ACCOUNT DATA
        // TIME TO GET SIGNATURES FOR ACCOUNT - WILL RESULT WILL BE IN THE NEXT SIG AFTER ONE PASSED...
        const signatures =
          await this.program.provider.connection.getSignaturesForAddress(
            gameInstancePubkey,
            {
              until: lastSignature,
            },
            "confirmed",
          );
        if (signatures.length == 0) {
          await sleep(1000);

          continue;
        }

        const parsedTxsWithMeta =
          await this.program.provider.connection.getParsedTransactions(
            signatures.map((signature) => {
              return signature.signature;
            }),
            {
              commitment: "confirmed",
              maxSupportedTransactionVersion: 0,
            },
          );

        parsedTxsWithMeta.forEach((parsedTx, index) => {
          const signature = signatures[index].signature;

          handleLogs(parsedTx, signature);
        });

        isFinished = true;
      }
    }
  }

  async closeOnLogsWsConnection(wsId: number) {
    try {
      await this.program.provider.connection.removeOnLogsListener(wsId);
    } catch (err) {
      console.warn("Issue closing GameSpec socket", err);
    }
  }

  subscribeToGameSpecEvents(
    onGameInstanceOrRoundCreatedEvent: Function,
    onBetPlaceEvent: Function,
    onBetResultEvent: Function,
    onGameInstanceResultEvent: Function,
    onGameInstanceClosedEvent: Function,
  ) {
    this.subscribeToGameEvents(
      this.publicKey,
      onGameInstanceOrRoundCreatedEvent,
      onBetPlaceEvent,
      onBetResultEvent,
      onGameInstanceResultEvent,
      onGameInstanceClosedEvent,
    );
  }

  deriveCallbackTablePubkey(randomnessRequestPubkey: PublicKey): PublicKey {
    const [pk, _] = PublicKey.findProgramAddressSync(
      [
        anchor.utils.bytes.utf8.encode("callback_table"),
        randomnessRequestPubkey.toBuffer(),
      ],
      RANDOM_PROGRAM_PUBKEY,
    );
    return pk;
  }

  async initAndBetSoloIxn(
    playerAccount: PlayerAccount,
    tokenMintPubkey: PublicKey,
    numberOfBets: number,
    betRequest: any, // Game Specific Enum Struct,
    instanceRequest: any, // Game Specific Enum Struct
    clientSeed: Buffer, // 4 bytes
    owner: PublicKey = this.program.provider.publicKey,
    referrerInput: PublicKey = playerAccount.referrerPubkey,
  ) {
    const referrer =
      referrerInput == null ? playerAccount.publicKey : referrerInput;
    const callerNonce = playerAccount.instanceNonce;
    const houseTokenVault =
      await this.house.deriveHouseTokenVault(tokenMintPubkey);
    const gameInstancePubkey = this.deriveNextSoloGameInstance(
      playerAccount.publicKey,
    );
    const playerTokenAccountPubkey =
      await playerAccount.deriveTokenAccountPubkey(tokenMintPubkey);
    const randomnessRequestPubkey = this.deriveRandomnessRequestPubkey(
      callerNonce,
      gameInstancePubkey,
    );
    const callbackTablePubkey = this.deriveCallbackTablePubkey(
      randomnessRequestPubkey,
    );
    const houseTokenPubkey =
      await this.house.deriveHouseTokenAccountPubkey(tokenMintPubkey);
    const oraclePubkey =
      this.house.getTokenConfigAndStatistics(tokenMintPubkey)?.oracle;

    return await this.program.methods
      .initAndBetSolo({
        numBets: numberOfBets,
        instanceRequest: instanceRequest,
        betRequest: betRequest,
        clientSeed: clientSeed,
      })
      .accounts({
        owner: owner,
        player: playerAccount.publicKey,
        gameSpec: this.publicKey,
        gameInstance: gameInstancePubkey,
        tokenMint: tokenMintPubkey,
        tokenAccount: playerTokenAccountPubkey,
        vault: houseTokenVault,
        oracle: oraclePubkey,
        house: this.house.publicKey,
        houseToken: houseTokenPubkey,
        globalSigner: this.deriveGlobalSignerPubkey(),
        platform: playerAccount.platform.publicKey,
        platformPayer: playerAccount.platform.derivePlatformPayerPubkey(),
        referrer: referrer,
        randomnessRequest: randomnessRequestPubkey,
        randomnessDispatcherSigner: this.deriveDispatcherPubkey(
          RANDOM_PROGRAM_PUBKEY,
        ),
        callbackTable: callbackTablePubkey,
        casinoProgram: this.program.programId,
        cashierProgram: this.house.program.programId,
        randomnessProgram: RANDOM_PROGRAM_PUBKEY,
        systemProgram: anchor.web3.SystemProgram.programId,
        tokenProgram: TOKEN_PROGRAM_ID,
      })
      .instruction();
  }

  getMultiplier(inputs: object): number {
    return 0;
  }

  getProbability(inputs: object): number {
    return 0;
  }

  // GIVEN AN ARRAY OF BETS WE RETURN META DATA LIKE PAYOUT, PROFIT AND WAGERED
  getBetMetas(bets: object[]): {
    bets: object[];
    wager: number;
    profit: number;
    payout: number;
    edgePercentage: number;
  } {
    throw new Error("getBetMetas Function not defined in specific game class");
  }

  async placeBetIx(
    player: PlayerAccount,
    inputs: object,
    wager: number,
    tokenMintPubkey: PublicKey,
    clientSeed: Buffer,
    owner: PublicKey,
    referrer?: PublicKey,
  ): Promise<anchor.web3.TransactionInstruction> {
    throw new Error("placeBetIx Function not defined in specific game class");
  }

  static deriveGameSpecAccountDiscriminator() {
    return Buffer.from(sha256.digest("account:GameSpec")).subarray(0, 8);
  }

  static async fetchGames(
    casinoProgram: Program,
    house: House,
    gameType?: GameType,
    authorityPubkey?: PublicKey,
  ): Promise<Game[]> {
    const filters = [
      {
        memcmp: {
          offset: 0, // Anchor account discriminator
          bytes: base58.encode(Game.deriveGameSpecAccountDiscriminator()),
        },
      },
      {
        memcmp: {
          offset: 138, // position of house pubkey
          bytes: base58.encode(house.publicKey.toBuffer()),
        },
      },
    ];
    if (authorityPubkey) {
      filters.push({
        memcmp: {
          offset: 10, // position of authority pubkey
          bytes: base58.encode(authorityPubkey.toBuffer()),
        },
      });
    }
    if (gameType) {
      filters.push({
        memcmp: {
          offset: 8, // position of gameType
          bytes: base58.encode(Buffer.from([Number(gameType)])),
        },
      });
    }
    const pubkeysAndBuffers =
      await casinoProgram.provider.connection.getProgramAccounts(
        casinoProgram.programId,
        {
          filters: filters,
        },
      );
    const games: Game[] = pubkeysAndBuffers.map((pubkeyAndBuffer) =>
      Game.loadFromBuffer(
        casinoProgram,
        house,
        pubkeyAndBuffer.pubkey,
        pubkeyAndBuffer.account.data,
      ),
    );

    return games;
  }
}
