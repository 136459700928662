import React, {
  FC,
  PropsWithChildren,
  ReactNode,
  useState,
  ButtonHTMLAttributes,
} from "react";
import IconFont, { IconName } from "../iconFont/IconFont";
import IconSvg from "../icon/Icon";
import IconButton from "../button/IconButton";

import { BASE_BUTTON_CLASSES } from "../../../styles/commonClasses";
import Tag from "../tag/Tag";

export interface NavItemProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  title: string | ReactNode;
  collapsed?: boolean;
  icon?: ReactNode;
  isTopLevel?: boolean;
  isSelected: boolean;
  isSoon?: boolean;
  isNew?: boolean;
  dropdownVisibleDefault?: boolean;
  iconName?: IconName;
}

const NavItem: FC<NavItemProps> = ({
  title,
  iconName,
  icon,
  collapsed,
  children,
  isTopLevel,
  isSelected,
  isSoon,
  isNew,
  dropdownVisibleDefault,
  onClick,
  ...props
}) => {
  const [isDropdownVisible, setDropdownVisible] = useState(
    dropdownVisibleDefault,
  );

  return (
    <li
      className={`flex flex-col w-full items-center ${isDropdownVisible ? "gap-1" : ""} `}
    >
      <button
        disabled={isSoon}
        role="button"
        className={`${BASE_BUTTON_CLASSES} 
        w-full shrink-0 transition-all overflow-hidden flex items-center gap-2 sm:rounded-lg rounded-md 
        hover:bg-gray-700 active:bg-gray-600 
        disabled:text-gray-400  disabled:hover:bg-gray-800 
        ${isTopLevel ? "text-gray-50" : "text-gray-200"}
        ${isSelected ? "bg-gray-700 text-gray-50" : "bg-gray-800"}
        
        ${collapsed ? "p-3" : "py-2 md:px-1.5 px-3"}
        `}
        onClick={
          onClick
            ? (e) => onClick(e)
            : () => setDropdownVisible((prev) => !prev)
        }
        {...props}
      >
        <div
          className={`flex items-center ${
            !collapsed && !children && isTopLevel ? "min-h-[32px]" : "-ml-[1px]"
          }`}
        >
          {iconName && <IconFont size="lg" name={iconName} className="" />}
          {icon && <IconSvg icon={icon} size="lg" />}
        </div>
        <div
          className={`flex justify-between whitespace-nowrap flex-nowrap overflow-hidden items-center transition-all ${
            collapsed ? "w-0" : "w-full"
          }`}
        >
          {typeof title === "string" ? (
            <span className={`${!isTopLevel && !collapsed ? "leading-6" : ""}`}>
              {title}
            </span>
          ) : (
            title
          )}
          {children && !collapsed && isTopLevel && (
            <IconButton
              size="sm"
              iconName="arrow_down"
              iconClass={`transition-transform ${isDropdownVisible ? "rotate-180" : ""}`}
              onClick={(e) => {
                e.stopPropagation();
                setDropdownVisible((prev) => !prev);
              }}
            />
          )}
          {isSoon && !collapsed && !children && (
            <Tag type="soon" title={"Soon"} />
          )}
          {isNew && !collapsed && !children && <Tag type="new" title={"New"} />}
        </div>
      </button>
      {children && !collapsed && isTopLevel && (
        <ul
          className={`overflow-hidden w-full transition-all flex flex-col md:gap-0 gap-1 ${
            isDropdownVisible ? "h-full" : "h-0"
          }`}
        >
          {children}
        </ul>
      )}
    </li>
  );
};

export default NavItem;
