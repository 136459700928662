import React, { FC } from "react";
import Input, { InputProps } from "../../../components/common/input/Input";

export interface PasswordInputProps extends InputProps {
  hidePass?: boolean;
}

const PasswordInput: FC<PasswordInputProps> = ({
  onChange,
  value,
  error,
  hidePass = true,
  ...rest
}) => {
  return (
    <Input
      placeholder={"Please enter pin"}
      {...rest}
      type="password"
      error={error}
      classes={{
        label: "h-[42px] flex-row",
        wrapper: "flex-1",
        input: "[text-security:disc]",
      }}
      value={value}
      onChange={(e) => {
        onChange(e.target.value);
      }}
    />
  );
};

export default PasswordInput;
