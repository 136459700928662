import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { PlatformContext } from "../../contexts/PlatformContext";
import Game from "../../sdk/gameSpec";
import { ProgramContext } from "../../contexts/ProgramContext";
import { Connection, PublicKey } from "@solana/web3.js";
import { NetworkContext } from "../../contexts/NetworkContext";
import { RandomnessDispatcherContext } from "../../contexts/RandomnessDispatcherContext";

export const BalanceContext = createContext<IBalanceContext>(
  {} as IBalanceContext,
);

interface Props {
  children: any;
}

export enum BalanceType {
  GLOBAL_SIGNER = "global signer",
  PLATFORM_PAYER = "platform payer",
  RANDOMNESS_PROVIDER = "randomness provider",
}

interface IBalanceToWatch {
  type: BalanceType;
  pubkey: PublicKey;
  lamportBalance?: number;
  solBalance?: number;
}

interface IBalanceContext {
  balanceByPubkey: Map<string, IBalanceToWatch> | undefined;
  balanceByType: Map<BalanceType, IBalanceToWatch> | undefined;
  isLoading: boolean;
  signerPubkeys: string[] | undefined;
}

// CONTEXT PROVIDING THE BALANCE OF IMPORTANT SIGNING WALLETS
export const BalanceProvider = ({ children }: Props) => {
  // STATE
  const [balancesToWatch, setBalancesToWatch] = useState<IBalanceToWatch[]>();
  const [balanceByPubkey, setBalanceByPubkey] =
    useState<Map<string, IBalanceToWatch>>();
  const [balanceByType, setBalanceByType] =
    useState<Map<BalanceType, IBalanceToWatch>>();
  const [loading, setLoading] = useState(true);
  const signerPubkeys = useMemo(() => {
    if (balancesToWatch == null) {
      return;
    }

    return balancesToWatch.map((balance) => {
      return balance.pubkey.toString();
    });
  }, [balancesToWatch]);

  // CONTEXT
  const { platform } = useContext(PlatformContext);
  const { meta } = useContext(ProgramContext);
  const { client } = useContext(NetworkContext);
  const { dispatcher } = useContext(RandomnessDispatcherContext);

  useEffect(() => {
    if (
      platform == null ||
      meta?.casinoProgram == null ||
      meta.randomnessProgram == null ||
      dispatcher == null
    ) {
      return;
    }

    const platformPayer = platform.derivePlatformPayerPubkey();
    const globalSigner = Game.deriveGlobalSignerPubkey(
      meta.casinoProgram.programId,
    );

    // Randomness Dispatcher
    const providers = dispatcher.listProviders.filter((provider) => {
      return Object.keys(provider.status).includes("active");
    });
    const randomnessProvider = providers[0];

    setBalancesToWatch([
      {
        type: BalanceType.PLATFORM_PAYER,
        pubkey: platformPayer,
      },
      {
        type: BalanceType.GLOBAL_SIGNER,
        pubkey: globalSigner,
      },
      {
        type: BalanceType.RANDOMNESS_PROVIDER,
        pubkey: randomnessProvider.pubkey,
      },
    ]);
  }, [platform, meta, dispatcher]);

  useEffect(() => {
    async function loadBalances(
      balances: IBalanceToWatch[],
      connection: Connection,
    ) {
      setLoading(true);

      // LOAD BALANCES FROM CHAIN
      const balancesLoaded = await Promise.all(
        balances.map((balance) => {
          return connection.getBalance(balance.pubkey, "processed");
        }),
      );

      // UPDATE VALUES
      const updatedBalances = [...balances].map((balance, index) => {
        const balValue = balancesLoaded[index];

        balance.lamportBalance = balValue;
        balance.solBalance = balValue / Math.pow(10, 9);

        return balance;
      });

      const balByPubkey = updatedBalances.reduce((result, item) => {
        result.set(item.pubkey.toString(), item);

        return result;
      }, new Map<string, IBalanceToWatch>());

      const balByType = updatedBalances.reduce((result, item) => {
        result.set(item.type, item);

        return result;
      }, new Map<BalanceType, IBalanceToWatch>());

      setBalanceByPubkey(balByPubkey);
      setBalanceByType(balByType);
      setLoading(false);
    }

    if (client == null) {
      return;
    }

    if (balancesToWatch == null || balancesToWatch.length == 0) {
      setBalanceByPubkey(undefined);
      setBalanceByType(undefined);

      return;
    }

    loadBalances(balancesToWatch, client);
  }, [balancesToWatch, client]);

  return (
    <BalanceContext.Provider
      value={useMemo(
        () => ({
          balanceByPubkey: balanceByPubkey,
          balanceByType: balanceByType,
          isLoading: loading,
          signerPubkeys: signerPubkeys,
        }),
        [balanceByPubkey, balanceByType, loading, signerPubkeys],
      )}
    >
      {children}
    </BalanceContext.Provider>
  );
};
