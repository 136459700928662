import { useContext } from "react";
import Icon from "../../components/common/icon/Icon";
import AdminBodyItem from "../Containers/AdminBodyItem";
import { BalanceContext, BalanceType } from "../Contexts/BalanceContext";
import {
  NumberType,
  formatZeebitNumber,
} from "../../utils/currency/formatting";
import { truncatePubkey } from "../../utils/string/string";
import IconButton from "../../components/common/button/IconButton";
import { useCopyToClipboard } from "../../hooks/useCopyToClipboard";

export const OverviewPage = () => {
  const { balanceByType } = useContext(BalanceContext);
  const { copyToClipboard } = useCopyToClipboard("Copied pubkey to clipboard");

  return (
    <>
      <AdminBodyItem title="Admin Dashboard">
        <div className="flex gap-10">
          <div className="flex flex-col gap-2">
            <span className="text-gray-400 uppercase font-normal">
              Global Signer Balance
            </span>

            <div className="flex gap-1.5 items-center">
              {truncatePubkey(
                balanceByType
                  ?.get(BalanceType.GLOBAL_SIGNER)
                  ?.pubkey.toString() || "",
                5,
              )}
              <IconButton
                size="xs"
                iconName="copy"
                onClick={() =>
                  copyToClipboard(
                    balanceByType
                      ?.get(BalanceType.GLOBAL_SIGNER)
                      ?.pubkey.toString() || "",
                  )
                }
              />
            </div>

            <div className="flex gap-2 items-center">
              <Icon iconUrl="/static/tokens/sol.png" size="xl" />
              <span className="text-3xl">
                {formatZeebitNumber(
                  balanceByType?.get(BalanceType.GLOBAL_SIGNER)?.solBalance ||
                    0,
                  NumberType.TOKEN_AMOUNT,
                  undefined,
                  8,
                )}
              </span>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <span className="text-gray-400 uppercase font-normal">
              Platform Payer Balance
            </span>
            <div className="flex gap-1.5 items-center">
              {truncatePubkey(
                balanceByType
                  ?.get(BalanceType.PLATFORM_PAYER)
                  ?.pubkey.toString() || "",
                5,
              )}
              <IconButton
                size="xs"
                iconName="copy"
                onClick={() =>
                  copyToClipboard(
                    balanceByType
                      ?.get(BalanceType.PLATFORM_PAYER)
                      ?.pubkey.toString() || "",
                  )
                }
              />
            </div>
            <div className="flex gap-2 items-center">
              <Icon iconUrl="/static/tokens/sol.png" size="xl" />
              <span className="text-3xl">
                {formatZeebitNumber(
                  balanceByType?.get(BalanceType.PLATFORM_PAYER)?.solBalance ||
                    0,
                  NumberType.TOKEN_AMOUNT,
                  undefined,
                  8,
                )}
              </span>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <span className="text-gray-400 uppercase font-normal">
              Randomness Provides Balance
            </span>
            <div className="flex gap-1.5 items-center">
              {truncatePubkey(
                balanceByType
                  ?.get(BalanceType.RANDOMNESS_PROVIDER)
                  ?.pubkey.toString() || "",
                5,
              )}
              <IconButton
                size="xs"
                iconName="copy"
                onClick={() =>
                  copyToClipboard(
                    balanceByType
                      ?.get(BalanceType.RANDOMNESS_PROVIDER)
                      ?.pubkey.toString() || "",
                  )
                }
              />
            </div>
            <div className="flex gap-2 items-center">
              <Icon iconUrl="/static/tokens/sol.png" size="xl" />
              <span className="text-3xl">
                {formatZeebitNumber(
                  balanceByType?.get(BalanceType.RANDOMNESS_PROVIDER)
                    ?.solBalance || 0,
                  NumberType.TOKEN_AMOUNT,
                  undefined,
                  8,
                )}
              </span>
            </div>
          </div>
        </div>
      </AdminBodyItem>
    </>
  );
};
