import { useContext, useMemo, useState } from "react";
import { PublicKey } from "@solana/web3.js";

import { Tab, TabSize } from "../../components/common/tab/Tab";
import Table, { ITableItem } from "../../components/common/table/Table";
import { PlatformContext } from "../../contexts/PlatformContext";
import AdminBodyItem from "../Containers/AdminBodyItem";
import Icon from "../../components/common/icon/Icon";
import {
  NumberType,
  formatZeebitNumber,
} from "../../utils/currency/formatting";
import { HouseContext } from "../../contexts/HouseContext";
import Button from "../../components/common/button/Button";
import { IPlatformToken } from "../../sdk/platform";
import { ICasinoToken } from "../../types/token";
import { useModal } from "react-modal-hook";
import { WithdrawProfitModal } from "../modals/platform/WithdrawProfitsModal";

import {
  TransferModal,
  TransferModalType,
} from "../modals/platform/TransferRewardsModal";

export const PlatformPage = () => {
  const { platform } = useContext(PlatformContext);
  const tabs = ["tokens"];
  const [currentTab, setCurrentTab] = useState<(typeof tabs)[number]>("tokens");
  const { house } = useContext(HouseContext);

  const { tokensByIdentifier } = useContext(PlatformContext);

  const [selectedToken, setSelectedToken] = useState<IPlatformToken>();
  const [selectedTokenContext, setSelectedTokenContext] =
    useState<ICasinoToken>();
  const [showPlatformWithdrawModal, hidePlatformWithdrawModal] = useModal(
    ({ in: open }): JSX.Element => (
      <WithdrawProfitModal
        visible={open}
        hideModal={hidePlatformWithdrawModal}
        houseToken={undefined}
        platformToken={selectedToken}
        tokenContext={selectedTokenContext}
      />
    ),
    [selectedToken, selectedTokenContext]
  );
  const [type, setType] = useState<TransferModalType>();
  const [showTransferModal, hideTransferModal] = useModal(
    ({ in: open }): JSX.Element => (
      <TransferModal
        visible={open}
        type={type}
        hideModal={hideTransferModal}
        platformToken={selectedToken}
        tokenContext={selectedTokenContext}
      />
    ),
    [selectedToken, selectedTokenContext, type]
  );

  const tokensTableData: ITableItem[] = useMemo(() => {
    if (tokensByIdentifier == null || platform?.tokens == null) {
      return [];
    }

    return platform?.tokens.map((tkn) => {
      const context = tokensByIdentifier.get(tkn.pubkey.toString());
      const tokenPubkey = new PublicKey(tkn.pubkey);
      const tokenDecimals = context?.houseToken?.decimals || 6;

      return {
        id: tkn.pubkey.toString(),
        token: {
          value: context?.context?.symbol,
          prefix: <Icon iconUrl={context?.context?.imageDarkPng} />,
          suffix: (
            <span className="text-gray-300">{context?.context?.symbol}</span>
          ),
          subValue: tkn.statusString,
        },
        revenueShareBalanceAccrued: {
          prefix: <Icon iconUrl={context?.context?.imageDarkPng} />,
          value: formatZeebitNumber(
            (tkn.revenueShareBalanceAccrued || 0) / Math.pow(10, tokenDecimals),
            NumberType.TOKEN_AMOUNT
          ),
          subValue: `~$${formatZeebitNumber(
            house?.approximateTokenAmountToBaseUI(
              tokenPubkey,
              tkn.revenueShareBalanceAccrued || 0
            ) || 0,
            NumberType.TOKEN_AMOUNT
          )}`,
        },
        revenueShareDrawndown: {
          prefix: <Icon iconUrl={context?.context?.imageDarkPng} />,
          value: formatZeebitNumber(
            (tkn.revenueShareDrawndown || 0) / Math.pow(10, tokenDecimals),
            NumberType.TOKEN_AMOUNT
          ),
          subValue: `~$${formatZeebitNumber(
            house?.approximateTokenAmountToBaseUI(
              tokenPubkey,
              tkn.revenueShareDrawndown || 0
            ) || 0,
            NumberType.TOKEN_AMOUNT
          )}`,
        },
        totalVolume: {
          prefix: <Icon iconUrl={context?.context?.imageDarkPng} />,
          value: formatZeebitNumber(
            (tkn.houseToken.pastWagered || 0) / Math.pow(10, tokenDecimals),
            NumberType.TOKEN_AMOUNT
          ),
          subValue: `~$${formatZeebitNumber(
            house?.approximateTokenAmountToBaseUI(
              tokenPubkey,
              tkn.houseToken.pastWagered || 0
            ) || 0,
            NumberType.TOKEN_AMOUNT
          )}`,
        },
        totalPayout: {
          prefix: <Icon iconUrl={context?.context?.imageDarkPng} />,
          value: formatZeebitNumber(
            (tkn.houseToken.pastPaidOut || 0) / Math.pow(10, tokenDecimals),
            NumberType.TOKEN_AMOUNT
          ),
          subValue: `~$${formatZeebitNumber(
            house?.approximateTokenAmountToBaseUI(
              tokenPubkey,
              tkn.houseToken.pastPaidOut || 0
            ) || 0,
            NumberType.TOKEN_AMOUNT
          )}`,
        },
        profitAndLoss: {
          prefix: <Icon iconUrl={context?.context?.imageDarkPng} />,
          value: formatZeebitNumber(
            (tkn.houseToken.pastPnl || 0) / Math.pow(10, tokenDecimals),
            NumberType.TOKEN_AMOUNT
          ),
          subValue: `~$${formatZeebitNumber(
            house?.approximateTokenAmountToBaseUI(
              tokenPubkey,
              tkn.houseToken.pastPnl || 0
            ) || 0,
            NumberType.TOKEN_AMOUNT
          )}`,
        },
        // rewardAccrualBalanceAccrued: {
        //   prefix: <Icon iconUrl={context?.context?.imageDarkPng} />,
        //   value: formatZeebitNumber(
        //     (tkn.houseToken.pastWagered || 0) / Math.pow(10, tokenDecimals),
        //     NumberType.TOKEN_AMOUNT
        //   ),
        //   subValue: `~$${formatZeebitNumber(
        //     house?.approximateTokenAmountToBaseUI(
        //       tokenPubkey,
        //       tkn.houseToken.pastWagered || 0
        //     ) || 0,
        //     NumberType.TOKEN_AMOUNT
        //   )}`,
        // },
        // rewardAccrualDrawndown: {
        //   prefix: <Icon iconUrl={context?.context?.imageDarkPng} />,
        //   value: formatZeebitNumber(
        //     (tkn.rewardAccrualDrawndown || 0) / Math.pow(10, tokenDecimals),
        //     NumberType.TOKEN_AMOUNT
        //   ),
        //   subValue: `~$${formatZeebitNumber(
        //     house?.approximateTokenAmountToBaseUI(
        //       tokenPubkey,
        //       tkn.rewardAccrualDrawndown || 0
        //     ) || 0,
        //     NumberType.TOKEN_AMOUNT
        //   )}`,
        // },
        // rewardFundingBalanceAvailable: {
        //   prefix: <Icon iconUrl={context?.context?.imageDarkPng} />,
        //   value: formatZeebitNumber(
        //     (tkn.rewardFundingBalanceAvailable || 0) /
        //       Math.pow(10, tokenDecimals),
        //     NumberType.TOKEN_AMOUNT
        //   ),
        //   subValue: `~$${formatZeebitNumber(
        //     house?.approximateTokenAmountToBaseUI(
        //       tokenPubkey,
        //       tkn.rewardFundingBalanceAvailable || 0
        //     ) || 0,
        //     NumberType.TOKEN_AMOUNT
        //   )}`,
        // },
        // rewardFundingReserved: {
        //   prefix: <Icon iconUrl={context?.context?.imageDarkPng} />,
        //   value: formatZeebitNumber(
        //     (tkn.rewardFundingReserved || 0) / Math.pow(10, tokenDecimals),
        //     NumberType.TOKEN_AMOUNT
        //   ),
        //   subValue: `~$${formatZeebitNumber(
        //     house?.approximateTokenAmountToBaseUI(
        //       tokenPubkey,
        //       tkn.rewardFundingReserved || 0
        //     ) || 0,
        //     NumberType.TOKEN_AMOUNT
        //   )}`,
        // },
        rewardTransfer: {
          value: (
            <div>
              <Button
                variant="secondary"
                onClick={() => {
                  setSelectedToken(tkn);
                  setSelectedTokenContext(context?.context);
                  setType(TransferModalType.WITHDRAW);
                  showTransferModal();
                }}
              >
                withdrawRewardTkns
              </Button>
              <div className="my-2"></div>
              <Button
                variant="secondary"
                onClick={() => {
                  setSelectedToken(tkn);
                  setSelectedTokenContext(context?.context);
                  setType(TransferModalType.DEPOSIT);
                  showTransferModal();
                }}
              >
                depositRewardTkns
              </Button>
            </div>
          ),
        },
        withdraw: {
          prefix: (
            <Button
              variant="secondary"
              onClick={() => {
                setSelectedToken(tkn);
                setSelectedTokenContext(context?.context);
                showPlatformWithdrawModal();
              }}
            >
              Withdraw
            </Button>
          ),
        },
      };
    });
  }, [platform?.tokens, tokensByIdentifier, house, showPlatformWithdrawModal]);

  const tokens = useMemo(() => {
    if (platform?.tokens == null || tokensByIdentifier == null) {
      return [];
    }

    return platform.tokens.map((tkn) => {
      const context = tokensByIdentifier.get(tkn.pubkey.toString());

      return {
        ...tkn,
        context: context?.context,
      };
    });
  }, [platform, platform?.tokens, tokensByIdentifier]);

  const tabContent = () => {
    if (currentTab === "general") {
      return (
        <AdminBodyItem title="Tokens">
          <Table
            items={tokensTableData}
            header={[
              "TOKEN",
              "rv_Sh_BalAc",
              "rv_Sh_Dd",
              "rw_Acc_BalAc",
              "rew_Acc_DD",
              "rew_Fun_Bal_Av",
              "rew_Fun_Res",
              "Rew Actions",
              "WITHDRAW",
            ]}
          />
        </AdminBodyItem>
      );
    } else if (currentTab === "tokens") {
      return (
        <Table
          items={tokensTableData}
          header={[
            "TOKEN",
            "rv_Sh_BalAc",
            "rv_Sh_Dd",
            "total_volume",
            "total_payout",
            "P&L",
            // "rw_Acc_BalAc",
            // "rew_Acc_DD",
            // "rew_Fun_Bal_Av",
            // "rew_Fun_Res",
            "INFO",
            "WITHDRAW",
          ]}
        />
      );
    }
  };

  return (
    <>
      <div className="flex gap-4">
        {tabs.map((tab) => (
          <Tab
            size={TabSize.MD}
            active={currentTab === tab}
            onClick={() => setCurrentTab(tab)}
            extraClasses="capitalize"
            disabled={
              ["general", "tokens", "custom referrals", "rewards"].includes(
                tab
              ) == false
            }
          >
            {tab}
          </Tab>
        ))}
      </div>
      {tabContent()}
    </>
  );
};
