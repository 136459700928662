import {
  ComputeBudgetProgram,
  ConfirmOptions,
  Connection,
  Keypair,
  Transaction,
  TransactionInstruction,
  TransactionMessage,
  VersionedTransaction,
} from "@solana/web3.js";
import * as CryptoJS from "crypto-js";

export const encryptSecretKey = (secretKey: string, pin: string) => {
  return CryptoJS.AES.encrypt(secretKey, pin).toString();
};

export const decryptSecretKey = (encryptedString: string, pin: string) => {
  return CryptoJS.AES.decrypt(encryptedString, pin).toString(CryptoJS.enc.Utf8);
};

// METHOD WHICH RETURNS THE DEFAULT PRE-IXNS TO USE ON A ADMIN TX
export const getPreIxns = (): TransactionInstruction[] => {
  return [
    ComputeBudgetProgram.setComputeUnitLimit({
      units: 1_000_000,
    }),
    ComputeBudgetProgram.setComputeUnitPrice({
      microLamports: 10_000,
    }),
  ];
};

export const toV0Transaction = (
  transaction: Transaction,
  blockhash: string,
  signer: Keypair,
): VersionedTransaction => {
  const messageV0 = new TransactionMessage({
    payerKey: signer.publicKey,
    recentBlockhash: blockhash,
    instructions: transaction.instructions,
  }).compileToV0Message();

  const v0Transaction = new VersionedTransaction(messageV0);
  v0Transaction.sign([signer]);

  return v0Transaction;
};

export const sendTxnMultipleTimesToMultipleRpcs = async (
  transaction: Transaction,
  blockhash: string,
  numberOfTries: number,
  clients: Connection[],
  signers: Keypair[],
  options: ConfirmOptions,
): Promise<string> => {
  // NEED TO CONVERT TO V0 TXN TO ENSURE SAME SIGNATURE
  const v0Tx = toV0Transaction(transaction, blockhash, signers[0]);

  // USING ALL SETTLED TO SUCCEED IF ANY SUCCEED
  const promises = await Promise.allSettled(
    clients.map((client) => {
      return Promise.all(
        new Array(numberOfTries)
          .fill(0)
          .map(() => client.sendTransaction(v0Tx, options)),
      );
    }),
  );

  // GO THROUGH ERRORS AND PRINT THEM OUT
  promises.forEach((promise, index) => {
    if (promise.status == "rejected") {
      console.warn(`-----------`);
      console.warn(`Issue with ${clients[index].rpcEndpoint}`);
      console.warn({
        promise,
      });
      console.warn(`-----------`);
    }
  });

  // GET THE FIRST ONE THAT SUCCEEDS
  const firstSuccess = promises.find((result) => result.status == "fulfilled");

  // IF NONE OF THEM SUCCEED FORMAT ERROR FOR LOGS
  if (firstSuccess == null) {
    const reasons = promises.map((promise) => {
      if ("reason" in promise) {
        return promise?.reason;
      } else {
        return "unknown reason";
      }
    });
    return Promise.reject(
      `All promises failed accross all providers. Reasons=${reasons.join(", ")}`,
    );
  }

  if (firstSuccess != null && "value" in firstSuccess) {
    return firstSuccess.value?.[0];
  }

  return Promise.reject("No Value in successful promise");
};
