import { Fragment, useMemo } from "react";
import { SidebarDesktop } from "../../../components/left-nav/SideBarDesktop";
import { useLocation, useNavigate } from "react-router-dom";
import { SCROLLBAR_CLASSES_BLACK } from "../../../styles/commonClasses";
import NavItem from "../../../components/common/navigation-item/NavItem";

export function LeftNav() {
  const navigate = useNavigate();
  const location = useLocation();
  const activePage = useMemo(() => {
    if (location == null) {
      return;
    }

    return location.pathname;
  }, [location]);
  const OPTIONS = [
    {
      icon: "show",
      name: "Overview",
      key: "",
      onClick: () => {
        navigate(``);
      },
    },
    {
      icon: "platform",
      name: "Platform",
      key: "platform",
      disabled: false,
      onClick: () => {
        navigate(`platform`);
      },
    },
  ];

  return (
    <Fragment>
      {/* Static sidebar for desktop */}
      <SidebarDesktop leftBarIsOpen={true}>
        <div
          className={`flex grow-0 md:grow flex-col md:overflow-y-auto ${SCROLLBAR_CLASSES_BLACK}`}
        >
          <nav className={`flex flex-col mb-2 mr-2`}>
            <ul role="list" className="flex flex-1 flex-col gap-y-1">
              {OPTIONS.map((option, index) => {
                return (
                  <NavItem
                    key={index}
                    isSelected={activePage != null && activePage == option.key}
                    isTopLevel
                    iconName={option.icon}
                    collapsed={false}
                    title={option.name}
                    onClick={option.onClick}
                    disabled={option.disabled}
                  />
                );
              })}
            </ul>
          </nav>
        </div>
      </SidebarDesktop>
    </Fragment>
  );
}
