import React, { FC, PropsWithChildren } from "react";

export interface AdminBodyItemProps {
  title?: string;
  titlePrefix?: React.ReactNode;
  titleSuffix?: string | React.ReactNode;
  rightHeaderContent?: string | React.ReactNode;
}

const AdminBodyItem: FC<PropsWithChildren<AdminBodyItemProps>> = ({
  title,
  children,
  titlePrefix,
  titleSuffix,
  rightHeaderContent,
}) => {
  return (
    <div className="rounded-lg bg-gray-800 p-6 flex flex-col gap-4">
      {(title || titlePrefix || titleSuffix || rightHeaderContent) && (
        <div className="flex items-center justify-between w-full">
          <div className="flex items-center gap-3">
            {titlePrefix}
            {title && <h1 className="text-xl font-bold">{title}</h1>}

            {titleSuffix &&
              (typeof titleSuffix === "string" ? (
                <span className="text-xl text-gray-400 font-normal">
                  {titleSuffix}
                </span>
              ) : (
                titleSuffix
              ))}
          </div>
          {rightHeaderContent}
        </div>
      )}
      {children}
    </div>
  );
};

export default AdminBodyItem;
